/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

var SearchSuggestion = (function() {
  let Movie = undefined;
  let Person = undefined;
  SearchSuggestion = class SearchSuggestion {
    static initClass() {
      Movie = class Movie {
        constructor(data) {
          this.data = data;
        }

        image_url() {
          return this.data.image_url || "/assets/default/adaptive/poster_vertical-e2b9ece6238886bfede1855643694b179ad3e629ff16334f8adf1650f1b6fd5c.svg"
        }

        render() {
          return `\
<a href="${this.data.url}" class="d-block">
  <div class="media">
    <img class="d-flex me-3 w-40px" src="${this.image_url()}" alt="${this.data.title}">
    <div class="media-body">
      <h6 class="my-0">
        ${this.data.title}
        <span class="badge bg-primary align-top">${this.data.kind}</span>
      </h6>
      <div class="text-truncate text-muted small">${this.data.oryginal_title} (${this.data.year})</div>
      <div class="truncate text-muted small">${this.data.actors}</div>
    </div>
  </div>
</a>\
`;
        }
      };
      Person = class Person {
        constructor(data) {
          this.data = data;
        }

        image_url() {
          return this.data.image_url || "/assets/default/adaptive/photo_vertical-84e0d781e086c8476cb873e734a1f9ab29a2b88d79998a3654b5699eb2e9de26.svg";
        }

        age() {
          if (this.data.birth_date) {
            return `\
<span data-age="true" data-birth-date="${this.data.birth_date}" data-death-date="${this.data.death_date}"></span>\
`;
          } else {
            return "";
          }
        }
        birth_place() {
          return this.data.birth_place || "";
        }

        render() {
          return `\
<a href="${this.data.url}" class="d-block">
  <div class="media">
    <img class="d-flex me-3 w-40px" src="${this.image_url()}" alt="${this.data.name}">
    <div class="media-body">
      <h6 class="my-0">
        ${this.data.name}
        <span class="badge bg-primary align-top">${this.data.kind}</span>
      </h6>
      <div class="text-truncate text-muted small">${this.age()} ${this.birth_place()}</div>
      <div class="truncate text-muted small">${this.data.biography}</div>
    </div>
  </div>
</a>\
`;
        }
      };
    }


    constructor() {
      this.select = this.select.bind(this);
      this.render = this.render.bind(this);
      $('.navbar-query').soulmate({
        url: '/suggestions/legacy',
        types: ['suggestions'],
        renderCallback: this.render,
        selectCallback: this.select,
        minQueryLength: 1,
        maxResults: 6
      });
    }

    select(term, data, type) {
      return window.location = data.url;
    }

    render(term, data, type) {
      if (data) { return this[data.type](data); }
    }

    movie(data) {
      const movie = new Movie(data);
      return movie.render();
    }

    person(data) {
      const person = new Person(data);
      return person.render();
    }
  };
  SearchSuggestion.initClass();
  return SearchSuggestion;
})();

$(() => new SearchSuggestion());
