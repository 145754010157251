import { Controller } from "@hotwired/stimulus"
import Bound from "bounds.js"
import "intersection-observer"

export default class extends Controller {

  get debug() {
    return navigator.userAgent.includes("debugAds=true")
  }

  initialize() {
    __tcfapi('addEventListener', 2, this.onConsestChange.bind(this))
    document.addEventListener("adsDataLoaded", this.connect.bind(this))
  }

  connect() {
    if (!this.isReadyConsent())  {
      this.element.classList.add("ads-waiting-consent")
    }

    if (this.isReady()) {
      if (!this.connected) {
        this.boundary = this.createBoundary()
        this.lazy ? this.watch() : this.render()
        this.element.classList.remove("ads-waiting-consent")
        this.connected = true
      }
    }
  }

  onConsestChange(tcData, success) {
    if (tcData.eventStatus == "useractioncomplete" ||
      tcData.eventStatus == "tcloaded") {
      this.setReadyConsent()
      this.connect()
    }
  }

  isReady() {
    return this.isReadyConsent() && this.isReadyData()
  }

  isReadyConsent() {
    return localStorage.getItem("isReadyConsent") == "yes"
  }

  setReadyConsent() {
    localStorage.setItem("isReadyConsent", "yes")
  }


  isReadyData() {
    return window.adsData !== undefined
  }

  watch() {
    this.boundary.watch(this.element, this.render.bind(this))
  }
  unWatch() {
    this.boundary.unWatch(this.element)
  }

  createBoundary() {
    return Bound({
      margins: {
        bottom: 400,
      }
    })
  }

  get lazy() {
    return this.data.get("lazy") !== "false"
  }

  get name() {
    return this.data.get("name")
  }

  get adsData() {
    let unitId = window.adsData.slots[this.name][0]
    return window.adsData.units[unitId] || ""
  }

  get scripts() {
    return this.element.getElementsByTagName('script')
  }

  render() {
    this.unWatch()
    if (this.debug) {
      this.renderDebug()
      return
    }
    this.element.innerHTML = this.adsData
    for (var i = 0; i < this.scripts.length; i++) {
      var s = document.createElement('script')
      var a = this.scripts[i].attributes
      for (var j = 0; j < a.length; j++) {
        s[a[j].nodeName] = a[j].value
      }
      if (this.scripts[i].innerHTML) {
        s.text = this.scripts[i].innerHTML
      }

      this.scripts[i].parentNode.replaceChild(s, this.scripts[i])
    }
    this.element.classList.add("loaded")
  }

  renderDebug() {
    this.element.innerHTML = `Unit: ${this.name}`
    this.element.classList.add("ads-debug")
  }
}
